import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

const appId = `${process.env.REACT_APP_APP_ID}` ? `${process.env.REACT_APP_APP_ID}` : 'investorrelationsdata';
const initParams = {};
initParams.dataspanApiUrl = `${process.env.REACT_APP_API_URL}`;

const root = ReactDOM.createRoot(document.getElementById(appId));
root.render(
  <React.StrictMode>
    <App params={initParams}/>
  </React.StrictMode>
);
