import React, { useEffect, useState } from 'react';
import axios from 'axios';
import classNames from 'classnames';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCircleNotch,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './App.css';

// Set up Font Awesome
library.add(faCircleNotch);

const App = (props) => {

  const { params } = props;

  // IR Data rows
  const [irData, setIrData] = useState(null);

  // Set up Module details
  useEffect(() => {

    if (!irData){
      // Load the batches on first load
      getFundData();
    }
  });

  // Get the IR Data for the app
  const getFundData = async () => {

    const request = {
      method: 'GET',
      url: `${params.dataspanApiUrl}/fundlist/ir/`,
      headers: {
        'Content-Type': 'application/json',
      },
    };

    try {

      // Get the fund data from dataspan
      const response = await axios(request);

      // Load the countries in the right order -> USA, Europe, Total
      let irDataToLoad = response.data.filter(row => row.ColumnDesc === 'USA');
      irDataToLoad = irDataToLoad.concat(response.data.filter(row => row.ColumnDesc === 'Europe'));
      irDataToLoad = irDataToLoad.concat(response.data.filter(row => row.ColumnDesc === 'Total'));
      setIrData(irDataToLoad);

    } catch (e) {
      console.log(e);
    }

  }

  // Create our number formatter.
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 0,
    currencySign: 'accounting',
  });

  // Format any number as a percent
  const formatPercent = (number, decimals=2, showPercent=true) => {
    return (
      number !== null && number !== undefined && number !== 0 ? parseFloat(number * 100).toFixed(decimals) + (showPercent ? "%" : '') : '-'
    )
  }

  const renderHeader = (fieldName) => {
    return irData.map( row => {

      return <td className='ir-header'>{row[fieldName]}</td>;

    })
  }

  const renderDollarField = (fieldName) => {
    return irData.map( row => {

      return <td className='dollar-field'>{currencyFormatter.format(row[fieldName])}</td>;

    })
  }

  const renderPercentField = (fieldName) => {
    return irData.map( row => {

      return <td className='percent-field'>{formatPercent(row[fieldName])}</td>;

    })
  }

  const renderDateField = (fieldName, showTopBorder) => {
    return irData.map( row => {

      const day = row[fieldName].substring(8, 10);
      const month = row[fieldName].substring(5, 7);
      const year = row[fieldName].substring(0, 4);

      return <td className={classNames('date-field', (showTopBorder ? 'border-row': ''))}>{month}/{day}/{year}</td>;

    })
  }

  return (
    <div className="app">
      <div className="ir-data">
        { !irData ? 
          <div className='waiting'>
            <FontAwesomeIcon icon="circle-notch" size="3x" className="waiting-icon" spin />
          </div>

        : 
            <div className="ir-table-container">
              <div className="ir-title"><h2>WisdomTree AUM</h2><h3>&nbsp;(in millions)</h3></div>
              <div className="ir-table-box">
                <table className='ir-table'>
                  <tbody>
                    <tr>
                      <td></td>
                      {renderHeader('ColumnDesc')}
                    </tr>
                    <tr>
                      <td className='ir-column-header border-row'>A/O Date</td>
                      {renderDateField('AvgAODate', true)}
                    </tr>
                    <tr>
                      <td className='ir-column-header'>AUM<sup>1</sup></td>
                      {renderDollarField('AUM')}
                    </tr>
                    <tr>
                      <td className='ir-column-header'>Avg AUM QTD<sup>2</sup></td>
                      {renderDollarField('AvgAUMQTD')}
                    </tr>
                    <tr>
                      <td className='ir-column-header'>Avg Fee<sup>3</sup></td>
                      {renderPercentField('AvgFee')}
                    </tr>
                    <tr>
                      <td className='ir-column-header border-row'>A/O Date</td>
                      {renderDateField('NetInflowsAODate', true)}
                    </tr>
                    <tr >
                      <td className='ir-column-header'>Net Inflows<sup>4</sup> MTD</td>
                      {renderDollarField('NetInflowsMTD')}
                    </tr>
                    <tr>
                      <td className='ir-column-header'>Net Inflows QTD</td>
                      {renderDollarField('NetInflowsQTD')}
                    </tr>
                    <tr>
                      <td className='ir-column-header'>Net Inflows YTD</td>
                      {renderDollarField('NetInflowsYTD')}
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="ir-table-footnotes">
                <p><sup>1</sup>AUM means the aggregate assets under management of the WisdomTree products as determined by the current net asset value of such products as of the close of business on the date indicated.</p>
                <p><sup>2</sup>Average AUM means the average of the daily AUM for each of the days in the quarter through the date indicated.</p>
                <p><sup>3</sup>Average Fee means the daily average of the product fees charged by the WisdomTree products from the commencement of the quarter and through the date indicated, weighted by the current net asset value of each product on each day included in the calculation.</p>
                <p><sup>4</sup>Net Inflows means the net aggregate value of creations and redemptions of shares of the WisdomTree products based upon the current net asset value per share on the date of creation or redemption, from the commencement of the quarter and through the date indicated.</p>

              </div>
            </div>

        }
      </div>
    </div>
  );
}

export default App;
